.sd-btn {
    background-color: black;
    border-color: black;
}
.sd-btn:hover {
    border-color: black;
}
.sd-item--checked .sd-item__decorator {
    background: var(--primary, #000);
}
.sd-item__control:focus + .sd-item__decorator, .sd-item--allowhover:hover .sd-item__decorator {
    box-shadow: 0 0 0 2px var(--primary, #000);
    outline: none;
}