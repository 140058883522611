.cart-card {
    margin: auto;
    max-width: 950px;
    width: 90%;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 1rem;
    border: transparent
}

@media(max-width:660px) {
    .cart-card {
        margin: 3vh auto
    }
    .row.title-mobile {
        font-size: 14px;
    }
    .row.price-mobile {
        font-size: 10px;
    }
    .row.quantity-mobile {
        font-size: 10px;
    }
    img.img-fluid.mobile {
        margin-left: 0%;
    }
}

.cart {
    background-color: #fff;
    padding: 4vh 5vh;
    border-bottom-left-radius: 1rem;
    border-top-left-radius: 1rem
}
@media(min-width:467px) {
    .quantity{
        padding-left: 27%;
        padding-right: 28%;
    }
}
// tab css 
@media(max-width:980px) {
    .product-number{
        padding: 25%;
    }
}
// web css 
@media(min-width:1080px) {
    .product-number{
        padding: 30%;
    }
    button.rounded-pill.add-product.btn.btn-success {
        margin-left: 10%;
    }
}
//mobile css 
@media(max-width:660px) {
    .cart {
        padding: 4vh;
        border-bottom-left-radius: unset;
        border-top-right-radius: 1rem
    }
    .product-number{
        padding: 0%;
    }
    button.rounded-pill.add-product.btn.btn-success {
        position: absolute;
        right: 10%;
        margin-top: 5%;
    }

    .mobile-button-qnt{
        margin-top: 5%;
    }
}

@media(max-width:660px) {
    .summary {
        border-top-right-radius: unset;
        border-bottom-left-radius: 1rem
    }
}

.summary .col-2 {
    padding: 0
}

.summary .col-10 {
    padding: 0
}

.summary {
    background-color: #ddd;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    padding: 4vh;
    color: rgb(65, 65, 65)
}

span.border {
    border: 0px solid !important;
    text-align: center;
    padding: 5% 25% 5% 25%;
    margin: -40%;
}
.title b {
    font-size: 1.5rem
}

.main {
    margin: 0;
    padding: 2vh 0;
    width: 100%
}

a {
    padding: 0 1vh
}

.close {
    margin-left: auto;
    font-size: 0.7rem
}

img.img-fluid {
    width: 5.5rem;
    margin-left: 33%;
}

hr {
    margin-top: 1.25rem
}

form {
    padding: 2vh 0
}

select {
    border: 1px solid rgba(0, 0, 0, 0.137);
    padding: 1.5vh 1vh;
    margin-bottom: 4vh;
    outline: none;
    width: 100%;
    background-color: rgb(247, 247, 247)
}

input {
    border: 1px solid rgba(0, 0, 0, 0.137);
    padding: 1vh;
    margin-bottom: 4vh;
    outline: none;
    width: 100%;
    background-color: rgb(247, 247, 247)
}

input:focus::-webkit-input-placeholder {
    color: transparent
}

a {
    color: black
}

a:hover {
    color: black;
    text-decoration: none
}

#code {
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0.253), rgba(255, 255, 255, 0.185)), url("https://img.icons8.com/small/16/000000/long-arrow-right.png");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: center
}