.aboutus-card.card-body{
    padding: 3%;
    color: white;
    background-color: black;
}
.question-card.card-body{
    color: white;
    background-color: black;
}
span.heading{
    font-weight: bold;
}
span.description{
    font-size: large;
}

.aboutus-details{
    padding-top: 10%;
}
// @media screen and (max-device-width: 480px) {
//     // .aboutus-image{
//     //     height: 380px;
//     //     width: 350px;
//     //     margin-left: -3%;
//     // }
//     .row.
// }
@media(max-width: 460px) {
    // .aboutus-image{
    //     height: 380px;
    //     width: 350px;
    //     margin-left: -2%;
    // }
    .row.mobile-view-img {
        width: 100%;
        display: block;
        margin: 0;
    }
    .col.mobile-view {
        width: 100%;
    }
    .aboutus-image {
        height: auto;
        margin: 0 auto;
        width: 100%;
    }
}
// .aboutus-image{
//     height: 500px;
//     width: 500px;
// }

// @media (max-width: 980px) {
//     .aboutus-image{
//         height: 320px;
//         width: 320px;
//         padding-right: 5%;
//     }
// }