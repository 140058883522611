html *
{
  font-style: normal;
  font-family: Poppins, sans-serif !important;
}
@import '~bootstrap/scss/bootstrap.scss';
a {
  color: black;
  text-decoration: none;
}
a:hover {
  color: black;
}

.accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion-item {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: #fff;
  border-bottom: 4px solid rgba(0, 0, 0, 0.125);
  border-left: 4px solid rgba(0, 0, 0, 0.125);
  border-right: 4px solid rgba(0, 0, 0, 0.125);
  border-color: black;
  margin-bottom: 2%;
}
.accordion-button:not(.collapsed) {
  color: black;
  background-color: #ffffff;
  border-bottom: 4px solid rgba(0, 0, 0, 0.125);
  border-color: black;
}

.accordion-button {
  color: black;
  background-color: #ffffff;
  border-top: 4px solid rgba(0, 0, 0, 0.125);
  border-color: black;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.accordion-button:focus {
  border-color: #2b2d31;
  box-shadow: 0 0 0 0.25rem rgba(3, 3, 3, 0.25);
}

.nav-tabs .nav-item .nav-link{
  color: black;
  border-top: 4px solid black;
  border-bottom: 1px solid black;
  border-right: 4px solid black;
  border-left: 4px solid black;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #fff;
  background-color: green;
  border-color: none;
  border-right: 4px solid black;
  border-left: 4px solid black;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.nav-tabs {
  border-bottom: 4px solid black;
}

.nav-tabs .nav-link:hover{
  color: #fff;
  background-color: green;
}
.rbt{
  padding: 5% 5% 40% 5%;
}
.checkout-card{
  margin: 0% 30%;
  padding: 1%;
  background-color: #000;
  color: #fff;
}
.checkout-button{
  margin-top: 10%;
}
@media(max-width:460px) {
  .rbt{
    padding: 10% 0% 100% 0%;
  }
  .checkout-card{
    margin: 0%;
    padding: 1%;
  }
  .checkout-button{
    margin-top: 0%;
  }
}

.nav-link {
  color: #fff;
}
.nav-link:hover, .nav-link:focus {
  color: #fff;
}
@media screen and (min-device-width: 991px) and (max-device-width: 1200px) { 
  p.card-text {
  height: 100px;
}
}

#showplan-section .plan-card {
  max-width: 300px;
}