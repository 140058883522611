.footer-base {
  background-color: #000;
  color: #fff;
  padding: 4% 7%;
  font-size: 12px;
}
ul {
  list-style-type: none;
}
.nav-link {
  color: #fff;
}

.footer-link {
  margin-left: -10%;
}

.nav-link:hover, .nav-link:focus {
  color: #fff;
}
.footer-links-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1 1 auto;
}
.footer-links-container::after {
  content: "";
  flex: auto;
}

.footer-column {
  margin-bottom: 20px;
}

.footer-column:not(:last-child) {
  padding-right: 30px;
}
.footer-logo,
.footer-column-head {
  margin-bottom: 10px !important;
}
.footer-column > li {
  margin-bottom: 3.5px;
}
.footer-contact-option {
  display: flex;
  align-items: center;
  margin-bottom: 15px !important;
}
.footer-column > li > .footer-option-icon {
  background: #fff;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.footer-payments-container {
  margin-top: 40px;
  margin-bottom: 30px;
}
.footer-payment-icon-container {
  width: 85px;
  margin: 0 10px;
  display: inline-flex;
}

.footer-bottom-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.footer-social-icon-container {
  background-color: #fff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-social-icon-container {
  display: inline-flex;
  margin: 10px 20px;
}

@media (max-width: 992px){
  .footer-brand{
    position: inherit; 
    left: none;
    margin-left: 0px !important; 
    display: block;
  }
}
