.tenure-card.card{
    margin-left: -8%;
}

.tenure-container{
    padding: 3%;
}
.tenurebox.card-body{
    border-style: solid;
    border-width: 7px;
}
.tenurebox.card-body:hover {
    cursor: pointer;
}
.tenure-center{
    margin-right: 8%;
}
.plan-title.card-title.h5{
    padding: 10%;
    text-align: center;
}

.mb-2.text-muted.card-subtitle.h6{
    padding: 1%;
    text-align: center;
}

p.card-text{
    padding: 4%;
    text-align: center;
}

.tenure-input-element {
    display: none;
}

.discount-text{
    font-size: 12px;
    color: green;
}

// .tenure-input-element:checked + .card-body {
//     background-color: black;
//     color: #fff;
//     border-color: black;
// }

// .tenure-input-element:checked + .card-body.form-select {
//     background-color: #fff;
//     color: black; 
// }

@media (max-width: 660px) {
    // .tenure-center.col-lg-5.offset-lg-5{
    //     padding-left: 15%;
    // }
    .tenure-card.card {
        margin-left: unset;
    }
}
@media (max-width: 460px) {
    .tenure-card.card {
        margin: 0 auto;
    }
    .tenure-center.col-lg-5.offset-lg-5{
        padding: 0!important;
    }
}

@media (max-width: 980px) and (min-width: 650px) {
    .card.tenure-card{
        margin-left: 61%;
    }
}
