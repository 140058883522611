// .plan-container{
//     padding: 3%;
// }

// @media (max-width: 660px) {
//     .plan-center.col{
//         padding-left: 14%;
//     }
// }

// @media (max-width: 560px) {
//     .plan-center.col{
//         padding-left: 14%;
//     }
// }

@media (max-width: 360px) {
    .plan-card{
        width: 18rem;
        margin: 0 auto;
    }
}

@media (max-width: 460px) {
    .plan-card{
        width: 18rem;
        margin: 0 auto;
    }
}

.planbox.card-body{
    border-style: solid;
    border-width: 7px;
}
.planbox.card-body:hover {
    cursor: pointer;
}

.plan-title.card-title.h5{
    padding: 10%;
    text-align: center;
}

.mb-2.text-muted.card-subtitle.h6{
    padding: 1%;
    text-align: center;
}

p.card-text{
    padding: 4%;
    text-align: center;
}

.card-input-element {
    display: none;
}

.card-input-element:checked + .card-body {
    background-color: black;
    color: #fff;
    border-color: black;
}

.card-input-element:checked + .card-body button.rounded-pill.btn.btn-success {
    background-color: #fff;
    color: black; 
}

.card-input-element:checked + .card-body button.rounded-pill.btn.btn-success::after{
    content: "ed";  
}

//TODO On click of the button card wont be active 