.group-header{
    text-align: center;
    padding: 1%;
}

.group-description{
    text-align: center;
}

button.rounded-pill.btn.btn-primary.btn.btn-primary {
    background-color: #fff;
    border-color: goldenrod;
    border-width: 4px;
    color: black;
}

.card-button{
    position: absolute;
    right: 5%;
    margin-top: 33%;
}


@media (max-width: 660px) {
    .group-header{
        font-size: 20px;
    }
    .group-description{
        font-size: 14px;
    }

    button.rounded-pill.btn.btn-primary.btn.btn-primary {
       font-size: 12px;
    }

    .card-button{
        position: absolute;
        right: 5%;
        margin-top: 45%;
    }
}

@media (max-width: 980px) {
    .card-button{
        position: absolute;
        right: 5%;
        margin-top: 85%;
    }
}