
@media (max-width: 992px) {
    .navbar {
        position: relative;
    }
    .navbar-brand {
        position: absolute;
        left: 50%;
        margin-left: -50px !important;  /* 50% of your logo width */
        display: block;
    }

    .ml-auto .navbar-nav {
        position: absolute;
        right: 0%;
        display: "-webkit-inline-box";
    }
}

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
 }

 img.gcs-head-icons {
    width: 40px;
}
